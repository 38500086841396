/* @import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400;500;600;700;900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@300;400;500;600;700;900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@400;700&family=Noto+Naskh+Arabic:wght@600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700;900&family=IBM+Plex+Sans+Arabic:wght@400;700&family=Noto+Naskh+Arabic:wght@600;700&display=swap'); */

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
