@import url(https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@300;400;500;600;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  -webkit-transform: scale(1.015);
          transform: scale(1.015);
}

/* @import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400;500;600;700;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@400;700&family=Noto+Naskh+Arabic:wght@600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700;900&family=IBM+Plex+Sans+Arabic:wght@400;700&family=Noto+Naskh+Arabic:wght@600;700&display=swap'); */

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

